import React from 'react'
import { Helmet } from 'react-helmet'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

import NavbarHeader from './navbar-header'
import Footer from './footer'

import 'bootstrap/dist/css/bootstrap.min.css'

export const theme = {
  colorWhite: '#fff',
  colorBlack: '#000',
  colorText: '#272727',
  colorYellow: '#ffce00',
  colorRed: '#de1d30',
  colorGreen: '#16562f',
  colorGreenLight: '#019662',
  colorGreenBright: '#91BD24',
  colorGray: '#c0c0c0',
  colorGrayLight: '#f4f4f4',
  colorGrayMiddle: '#707070',
  fontBNPPSans: `'BNPP Sans', sans-serif`,
  fontBNPPSansCondensed: `'BNPP Sans Condensed', sans-serif`,
  fontAmaticSC: `'Amatic SC', cursive`,
  fontGothamCondensed: `'GothamCondensed', sans-serif`,
  fontVT323: `'VT323', monospace`,
  fontHelveticaNeue: `'Helvetica Neue', sans-serif`,
}

const GlobalStyle = createGlobalStyle`
  body {
    color: ${(props) => props.theme.colorBlack};
    font-family: ${(props) => props.theme.fontBNPPSans};
    font-size: 18px;
    font-weight: 400;
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 16px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 14px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 13px;
    }
    @media (max-width: 575px) {
      font-size: 12px;
    }
  }

  .container {
    @media (min-width: 1400px) {
      max-width: 1360px;
    }
    @media (min-width: 1200px) and (max-width: 1399px) {
      max-width: 1110px;
    }
  }
`

const Layout = ({ children, location, page = null }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Helmet />
      {/*page !== 'quiz' && <NavbarHeader />*/}

      {children}
      {page !== 'quiz' && <Footer location={location} />}
    </ThemeProvider>
  )
}

export default Layout
